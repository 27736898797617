var prodcat = prodcat || {};
prodcat.ui = prodcat.ui || {};
prodcat.data = prodcat.data || {};

var site = site || {};
site.onLoadRpc = site.onLoadRpc || {};
site.onLoadRpc.requests = site.onLoadRpc.requests || [];

prodcat.data.collectProductIds = function($context) {
  var prodIds = [];
  $('[data-product-id]', $context).each(function() {
    var $this = $(this);
    var prodId = $this.attr('data-product-id');
    var insert = true;
    for (var i = prodIds.length - 1; i > -1; i--) {
      if (prodIds[i] === prodId) {
        insert = false;
        break;
      }
    }
    if (insert) {
      prodIds.push(prodId);
    }
    insert = true;
  });
  return prodIds;
};

/**
 * Retrieves product data from data store. Folds full sku data into product.skus array
 * @param {String} prodId
 */
prodcat.data.getProduct = function(prodId) {
  if (!prodcat.data.store.products[prodId]) {
    return null;
  }
  var prodData = $.extend( true, {}, prodcat.data.store.products[prodId] );
  _.each(prodData.skus, function(skuId, idx, skus) {
    prodData.skus[idx] = prodcat.data.getSku(skuId);
  });
  return prodData;
};

prodcat.data.getSku = function(skuId) {
  skuId = skuId + ''; // Has to be a string to run indexOf
  skuId = skuId.indexOf('SKU') === 0 ? skuId : 'SKU' + skuId;
  var skuData = prodcat.data.store.skus[skuId];
  if (!skuData){
    return null;
  }
  return skuData;
};

/**
 * Sanitize the product data to ensure a consistent data structure
 */
prodcat.data.sanitizeProductData = function(product) {
  // Force the following fields to be an array even if they're originally a string
  var pf = ['IMAGE_L', 'IMAGE_M', 'IMAGE_S', 'IMAGE_XL', 'IMAGE_XM', 'IMAGE_XXL'],
      fk = '';
  for (var i = pf.length; i--;) {
    fk = pf[i];
    if (_.isUndefined(product[fk])) {
      continue;
    }
    product[fk] = (typeof product[fk] === 'string') ? [ product[fk] ] : product[fk];
  }

  return product;
};

prodcat.data.updateProducts = function(productsArray, deepCopy, responseDate) {
  deepCopy = (deepCopy === false) ? deepCopy : true;  // do a deep copy of the product data by default
  _.each(productsArray, function(newProd) {
    if( _.isNull(newProd) ){ return; }
    var targetProd = $.extend(deepCopy, {}, newProd);
    var oldProd = prodcat.data.store.products[newProd.PRODUCT_ID];

    if (_.isArray(newProd.skus)) {
      prodcat.data.updateSkus(targetProd.skus, responseDate);
      targetProd.skus = _.map(targetProd.skus, function(sku) {
        return sku.SKU_ID;
      });
    }
    targetProd.isPaletteMultiProduct = 0;
    if (prodcat.data.isPaletteMultiProduct(targetProd.PRODUCT_ID)) {
      targetProd.isPaletteMultiProduct = 1;
    }
    prodcat.data.store.products[targetProd.PRODUCT_ID] = prodcat.data.sanitizeProductData(_.isObject(oldProd) ? _.extend(oldProd, targetProd) : targetProd);
  });
};

/**
 * Sanitize the sku data to ensure a consistent data structure
 */
prodcat.data.sanitizeSkuData = function(sku) {
  // Remove any "product" keys from the sku object to prevent recursion errors down the road.
  sku.product = undefined;

  // Force the following fields to be an array even if they're originally a string
  var sf = ['IMAGE_SMOOSH_L', 'IMAGE_SMOOSH_S', 'IMAGE_SMOOSH_XL'],
      fk = '';
  for (var i = sf.length; i--;) {
    fk = sf[i];
    if (_.isUndefined(sku[fk])) {
      continue;
    }
    sku[fk] = (typeof sku[fk] === 'string') ? [ sku[fk] ] : sku[fk];
  }
  if (!_.isUndefined(sku['SHADENAME'])) {
    var shadeName = sku['SHADENAME'];
    sku['SHADENAME'] = shadeName ? shadeName.replace(/(\[#--)(.*)(--#\])/, '$2') : '';
  }
  return sku;
};

prodcat.data.updateSkus = function(skusArray, responseDate) {
  _.each(skusArray, function(newSku) {
    newSku = prodcat.data.sanitizeSkuData(newSku);
    if(responseDate) {
      newSku['responseDate'] = responseDate;
    }
    var oldSku = prodcat.data.store.skus[newSku.SKU_ID];
    prodcat.data.store.skus[newSku.SKU_ID] = _.isObject(oldSku) ? _.extend(oldSku, newSku) : newSku;
  });
};

prodcat.data.init = function() {
  prodcat.data.store = {
    categories: {},
    products: {},
    skus: {}
  };

  if (!page_data || typeof page_data === "undefined") {
    return null;
  }

  for (var key in page_data) {
    if (!page_data[key]) {
      continue;
    }
    if (page_data[key].categories && _.isArray(page_data[key].categories)) {
      _.each(page_data[key].categories, function(newCat) {
        var oldCat = prodcat.data.store.categories[newCat.CATEGORY_ID];
        var targetCat = $.extend(true, {}, newCat);
        if (_.isArray(targetCat.products)) {
          prodcat.data.updateProducts(targetCat.products);
        }
        targetCat.products = _.map(targetCat.products, function(prod) {
          return prod.PRODUCT_ID;
        });
        prodcat.data.store.categories[targetCat.CATEGORY_ID] = _.isObject(oldCat) ? _.extend(oldCat, targetCat) : targetCat;
      });
    }
    if (page_data[key].products && _.isArray(page_data[key].products)) {
      prodcat.data.updateProducts(page_data[key].products);
    }
    if (page_data[key].product && _.isObject(page_data[key].product)) {
      prodcat.data.updateProducts([page_data[key].product]);
    }
  }
};

/*
 * Success handler for l2 (catalog-mpp-volatile) calls.
 */
prodcat.data._l2_request_success_handler = function(r) {
  var prods = [];
  var v;
  if (!_.isUndefined(r.getValue)) {
    v = r.getValue();
    if (!!v && !!v.products) {
      prods = v.products;
    }
    else {
      return null;
    }
  } else if (
    !r ||
    !r.result ||
    !r.result.value ||
    !r.result.value.products
  ) {
    return null;
  } else {
    prods = r.result.value.products;
  }

  //Behaviour for result page is handled in endeca js
  if(typeof r.result === 'undefined') {
    r.result = [];
    r.result.data = [];
    r.result['data']['responseDate'] = false;
  }

  /*
   * L2_DANCE_PARTY
   *
   * Basically a debug tool that will randomly assign inv status to l2 calls if
   * the L2_DANCE_PARTY cookie is set.
   */
  if (!_.isUndefined(JSBoot)) {
    if (JSBoot.cookie.get('L2_DANCE_PARTY')) {
      console.log("L2 DANCE PARTY! :partyparrot:");
      var _statuses = [1, 2, 3, 4, 5, 7];
      $.each(prods, function(i, product) {
        if (_.isEmpty(product)) {
          return;
        }
        _.forEach(product.skus, function(sku) {
          var fake_status = _statuses[_.random(_statuses.length - 1)];
          sku['INVENTORY_STATUS'] = fake_status;
        })
      });
    }
  }

  prodcat.data.updateProducts(prods, true, r.result.data.responseDate);
  _.each(prods, function(prod) {
    if( _.isNull(prod) ){ return; }
    var prodSlctr = ".js-product[data-product-id='" + prod.PRODUCT_ID + "']";
    $(prodSlctr).trigger("inv_status_data:updated");
  });
};

site.l2Request = {
  "method": "prodcat.querykey",
  "apiFilterName": "prodcat-l2",
  "getParams" : function() {
    var pids = prodcat.data.collectProductIds(document);
    return [{
      products: pids,
      query_key: 'catalog-mpp-volatile'
    }];
  },
  "onSuccess": function (r) {
    return prodcat.data._l2_request_success_handler(r);
  }
};

/*
 * L2 request that is drupal behaviors compatible.
 */
prodcat.data.getNewProducts = function($context) {
  var pids = prodcat.data.collectProductIds($context);
  if (pids.length == 0) {
    return;
  }

  generic.jsonrpc.fetch({
    method:   "prodcat.querykey",
    params: [{
      products: pids,
      query_key: 'catalog-mpp-volatile'
    }],
    onSuccess: function (r) {
      return prodcat.data._l2_request_success_handler(r);
    }
  });
};


prodcat.data.isPaletteMultiSku = function(prodId) {
  var prodData = prodcat.data.getProduct(prodId);
  return !!prodData.isPaletteMultiSku;
};

prodcat.data.isPaletteMultiProduct = function(prodId) {
  if (!page_data["spp_palette_multiprod"]) return false;
  if (!page_data["spp_palette_multiprod"]["products"]) return false;
  var prodIds = _.pluck(page_data['spp_palette_multiprod']['products'], 'PRODUCT_ID');
  return !!(prodIds.indexOf(prodId) > -1);
  
};

prodcat.data.getPaletteMultiProducts = function() {
  if (!page_data["spp_palette_multiprod"]) return [];
  if (!page_data["spp_palette_multiprod"]["products"]) return [];
  var prodIds = _.pluck(page_data['spp_palette_multiprod']['products'], 'PRODUCT_ID');
  var prods = [];
  _.each(prodIds, function(prodId, i) {
    prods[i] = prodcat.data.getProduct(prodId);
  });
  return prods;
};

// Only add to the onLoadRpc stack if we have products
var pids = prodcat.data.collectProductIds(document);
if (pids.length > 0) {
  site.onLoadRpc.requests.push(site.l2Request);
}

/**
 * Pull data from page_data and store internally.
 */
Drupal.behaviors.prodcatDataInit = {
  attached: false,
  attach: function(context, settings) {
    if (this.attached == false) {
      prodcat.data.init();
    }
    this.attached = true;
  }
};
